import React, { useEffect, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  CircularProgress,
  DialogContentText,
  Box,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Grid2 as Grid } from '@mui/material';
import { IInitSignResponse } from '../../models/initInsigniaResponse';
import { FaPortfolioDto } from '../../models/portfolio';
import { PortfolioAgreementEnum } from '../../Enums';
import { Common } from '../../util/common';
import { useApiClient } from '../../hooks/apiClient';
import { getActiveUser, getMainUser } from '../../redux/stateFuncs';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { setToDoComplete } from '../../redux/slices/todo';
import { ToDoEnum } from '../../Enums';
import { useTranslation } from 'react-i18next';
import {
  DataGridPremium as DataGrid,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid-premium';

function PortfolioAgreement(props: any) {
  const { t } = useTranslation();
  const api = useApiClient();
  const users = useAppSelector((state) => state.users);
  const dispatch = useAppDispatch();

  var isChecking = false;

  const isToDo: boolean | undefined = props.isToDo;
  const activeUserCanSign = getActiveUser(users)?.canSign;

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('Agreement'),
      flex: 2.0,
      minWidth: 140,
      renderCell: (params: GridRenderCellParams<FaPortfolioDto>) => (
        <>{`${params.row.faPortfolioKey} - ${params.row.portfolioType} ${params.row.portfolioAgreement}`}</>
      ),
    },
    {
      field: 'portfolioId',
      headerName: '',
      flex: 1.0,
      minWidth: 170,
      renderCell: (params: GridRenderCellParams<FaPortfolioDto>) => (
        <Button
          className="agreement-table-button"
          sx={{ ml: 2 }}
          size="small"
          variant="outlined"
          color="primary"
          onClick={() => {
            handleClickDownload(params.row?.portfolioId!);
          }}
        >
          {t('Download')} {t('Agreement')}
        </Button>
      ),
    },
    {
      field: 'id',
      headerName: '',
      flex: 1.0,
      minWidth: 290,
      renderCell: (params: GridRenderCellParams<FaPortfolioDto>) => (
        <Button
          className="agreement-table-button"
          disabled={
            params.row?.portfolioAgreementValue ===
              PortfolioAgreementEnum.Advisory ||
            params.row?.portfolioAgreementValue ===
              PortfolioAgreementEnum.NoAgreement
          }
          size="small"
          sx={{ ml: 2 }}
          variant="outlined"
          color="primary"
          onClick={() => handleClickExante(params.row?.id!)}
        >
          {t('Download')} {t('Exante')}
        </Button>
      ),
    },
  ];

  const columnsApproval: GridColDef[] = [
    {
      field: 'name',
      headerName: t('Agreement') + ' Desktop',
      flex: 2.0,
      minWidth: 140,
      renderCell: (params: GridRenderCellParams<FaPortfolioDto>) => (
        <>{`${params.row.faPortfolioKey} - ${params.row.portfolioType} ${params.row.portfolioAgreement}`}</>
      ),
    },
    {
      field: 'portfolioId',
      headerName: '',
      flex: 0.8,
      minWidth: 150,
      renderCell: (params: GridRenderCellParams<FaPortfolioDto>) => (
        <>
          <Button
            className="agreement-table-button"
            sx={{ ml: 2 }}
            variant="outlined"
            startIcon={<DownloadIcon />}
            size="small"
            color="primary"
            onClick={() => {
              handleClickDownload(params.row?.portfolioId!);
            }}
          >
            {t('Agreement')}
          </Button>
        </>
      ),
    },
    {
      field: 'id',
      headerName: '',
      flex: 0.9,
      minWidth: 240,
      renderCell: (params: GridRenderCellParams<FaPortfolioDto>) => (
        <Button
          className="agreement-table-button"
          disabled={
            params.row?.portfolioAgreementValue ===
              PortfolioAgreementEnum.Advisory ||
            params.row?.portfolioAgreementValue ===
              PortfolioAgreementEnum.NoAgreement
          }
          size="small"
          sx={{ ml: 2 }}
          variant="outlined"
          color="primary"
          startIcon={<DownloadIcon />}
          onClick={() => handleClickExante(params.row?.id!)}
        >
          {t('Exante')}
        </Button>
      ),
    },
    {
      field: 'sign',
      headerName: '',
      flex: 1.0,
      minWidth: 255,
      renderCell: (params: GridRenderCellParams<FaPortfolioDto>) => (
        <>
          {activeUserCanSign ? (
            <>
              <Button
                size="small"
                sx={{ ml: 2 }}
                variant="contained"
                color="primary"
                startIcon={<CheckCircleOutlineIcon />}
                onClick={() =>
                  handleClickSign(params.row.id, params.row.portfolioId, true)
                }
              >
                {t('Confirm')}
              </Button>
              <Button
                size="small"
                sx={{ ml: 2 }}
                variant="outlined"
                color="primary"
                startIcon={<HighlightOffIcon />}
                onClick={() => handleClickReject(params.row.id!)}
              >
                {t('Reject')}
              </Button>
            </>
          ) : null}
        </>
      ),
    },
  ];

  const columnsApprovalMobile: GridColDef[] = [
    {
      field: 'name',
      headerName: t('Agreement'),
      flex: 1.0,
      minWidth: 140,
      renderCell: (params: GridRenderCellParams<FaPortfolioDto>) => (
        <>
          <Grid container>
            <Grid size={{ xs: 12 }} mt={1}>
              <Typography className="small-dg-text">
                {`${params.row.faPortfolioKey} - ${params.row.portfolioType} ${params.row.portfolioAgreement}`}
              </Typography>
            </Grid>
            <Grid size={{ xs: 12 }} height={'36px'} lineHeight={'36px'}>
              <Box>
                <Button
                  className="agreement-table-button"
                  variant="outlined"
                  startIcon={<DownloadIcon />}
                  size="small"
                  color="primary"
                  onClick={() => {
                    handleClickDownload(params.row?.portfolioId!);
                  }}
                >
                  {t('Agreement')}
                </Button>

                <Button
                  className="agreement-table-button"
                  disabled={
                    params.row?.portfolioAgreementValue ===
                      PortfolioAgreementEnum.Advisory ||
                    params.row?.portfolioAgreementValue ===
                      PortfolioAgreementEnum.NoAgreement
                  }
                  size="small"
                  sx={{ ml: 2 }}
                  variant="outlined"
                  color="primary"
                  startIcon={<DownloadIcon />}
                  onClick={() => handleClickExante(params.row?.id!)}
                >
                  {t('Exante')}
                </Button>
              </Box>
            </Grid>
            <Grid size={{ xs: 12 }} height={'36px'} lineHeight={'36px'}>
              {activeUserCanSign ? (
                <>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    startIcon={<CheckCircleOutlineIcon />}
                    onClick={() =>
                      handleClickSign(
                        params.row.id,
                        params.row.portfolioId,
                        true
                      )
                    }
                  >
                    {t('Confirm')}
                  </Button>
                  <Button
                    size="small"
                    sx={{ ml: 2 }}
                    variant="outlined"
                    color="primary"
                    startIcon={<HighlightOffIcon />}
                    onClick={() => handleClickReject(params.row.id!)}
                  >
                    {t('Reject')}
                  </Button>
                </>
              ) : null}
            </Grid>
          </Grid>
        </>
      ),
    },
  ];

  const handleClickDownload = (portfolioAgreement: string) => {
    api
      .get(`api/portfolio/getfile/${portfolioAgreement}`, {
        method: 'GET',
      })
      .then(
        (res) => {
          const url = res.data.url;
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', res.data.fileName);
          document.body.appendChild(link);
          link.click();
        },
        (err) => {
          alert('Error');
          setIsProgress(false);
        }
      );
  };

  const handleClickExante = (portfolioAgreement: string) => {
    setIsProgress(true);
    setDocumeentGenerationProgress(0);
    api
      .get(
        `api/DocumentGenerator/ExecutePortfolioDocumentFlow/${portfolioAgreement}`,
        {
          method: 'GET',
          responseType: 'text',
        }
      )
      .then(
        (res) => {
          let result = JSON.parse(res.data);
          if (result.status === 'Running' || result.status === 'Queued') {
            checkFlowResult(result.id);
          } else if (result.status === 'Error' || result.status === '500') {
            alert(result.message);
            setIsProgress(false);
            setDocumeentGenerationProgress(0);
          } else {
            let documentObject = Common.getObject(result.model, 'document');
            getDocumentContent(documentObject.document.id);
          }
        },
        (err) => {
          console.error(err);
          setIsProgress(false);
          setDocumeentGenerationProgress(0);
        }
      );
  };

  const checkFlowResult = (executionId: string) => {
    api
      .get(`api/DocumentGenerator/CheckDocumentExecution/${executionId}`, {
        method: 'GET',
        responseType: 'text',
      })
      .then(
        (res) => {
          let result = JSON.parse(res.data);
          setDocumeentGenerationProgress(result.percentage);
          if (result.status === 'Running' || result.status === 'Queued') {
            checkFlowResult(result.id);
          } else if (result.status === 'Error' || result.status === '500') {
            alert(result.message);
            setIsProgress(false);
            setDocumeentGenerationProgress(0);
          } else {
            let documentObject = Common.getObject(result.model, 'document');
            getDocumentContent(documentObject.document.id);
          }
        },
        (err) => {
          setIsProgress(false);
          setDocumeentGenerationProgress(0);
        }
      );
  };

  const getDocumentContent = (documentId: string) => {
    api
      .get(`api/DocumentGenerator/GetDocumentContent/${documentId}`, {
        method: 'GET',
        responseType: 'text',
      })
      .then(
        (res) => {
          const url = 'data:application/octet-stream;base64,' + res.data;
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Portfolio Exante.pdf');
          document.body.appendChild(link);
          setIsProgress(false);
          setDocumeentGenerationProgress(0);
          link.click();
        },
        (err) => {
          setIsProgress(false);
          setDocumeentGenerationProgress(0);
        }
      );
  };

  const handleClickSign = (
    id: string,
    portfolioId: string,
    isAccept: boolean
  ) => {
    setIsQrCodeFetched(false);

    var activeUser = getActiveUser(users);
    var activeEntityId = activeUser?.id;
    var entityType = activeUser?.isCompany === true ? 'account' : 'contact';

    const mainUserId = getMainUser(users)?.id;
    const mainUserSSN = getMainUser(users)?.socialSecurityNumber;

    let intervalId: number | undefined;
    let counter = 0;
    let initSigningResponse: IInitSignResponse;

    api
      .post('api/Signing/InitSigningPortfolio', {
        socialSecurityNumber: mainUserSSN,
        time: counter,
        portfolioId: portfolioId,
        opportunityId: id,
      })
      .then((res) => {
        initSigningResponse = res.data as IInitSignResponse;
        setInitSignResponse(initSigningResponse);
        setIsQrCodeFetched(true);
      })
      .then(() => {
        intervalId = window.setInterval(() => {
          if (counter > 20) {
            window.clearInterval(intervalId);
            setIsQrCodeFetched(false);
            api
              .post(`api/Signing/CancelSign/${initSigningResponse.orderRef}`)
              .then((res) => {
                setIsProgress(false);
              });
            setApiError({
              ...apiError,
              isOpen: true,
              Error: 'Signing Error',
              InnerError:
                'The allowed time for signing the document has been exceeded. To repeat the operation, click on the confirm button.',
            });
          } else {
            if (!isChecking) {
              isChecking = true;
              counter++;

              initSigningResponse.time = counter;
              initSigningResponse.approverId = mainUserId;
              initSigningResponse.entityId = id;
              initSigningResponse.ownerId = activeEntityId;
              initSigningResponse.customerLogicalName = entityType;
              initSigningResponse.entityLogicalName = 'opportunity';
              initSigningResponse.socialSecurityNumber = mainUserSSN;

              api
                .post(
                  `api/Signing/GetSigningAndApprovePortfolio`,
                  initSigningResponse
                )
                .then(
                  (res) => {
                    initSigningResponse = res.data as IInitSignResponse;
                    setInitSignResponse(initSigningResponse);
                    if (initSigningResponse.status === 'complete') {
                      let statuscode = isAccept ? 'Won' : 'Canceled';

                      const newPortfolios = portfolios.map((p) => {
                        if (p.id === id) {
                          p.statuscode = statuscode;
                        }
                        return p;
                      });
                      setPortfolios(newPortfolios);
                      window.clearInterval(intervalId);
                      setIsQrCodeFetched(false);
                      setIsFetched(true);
                      setIsSigning(false);

                      // If no more portfolis
                      if (
                        isToDo &&
                        !newPortfolios.find(
                          (p) => p.statuscode === 'UnderRevision'
                        )
                      ) {
                        dispatch(setToDoComplete(ToDoEnum.PortfolioAgreement));
                      }
                    }

                    isChecking = false;
                  },
                  (err) => {
                    isChecking = false;
                    api.post(
                      `api/Signing/CancelSign/${initSigningResponse.orderRef}`
                    );
                    window.clearInterval(intervalId);
                    setIsQrCodeFetched(false);
                    setIsSigning(false);
                    if (
                      err.response.data.additionalDetails.message !==
                      'InvalidParameters: No such order'
                    )
                      setApiError({
                        ...apiError,
                        isOpen: true,
                        Error: 'Signing Error',
                        InnerError: err.response.data.additionalDetails.message,
                      });
                  }
                );
            }
          }
        }, 2000);
      });
  };

  const handleClickReject = (id: string) => {
    const portfolio: FaPortfolioDto | undefined = portfolios.find(
      (p) => p.id === id
    );

    setIsProgress(true);
    var activeUser = getActiveUser(users);
    var activeEntityId = activeUser?.id;
    var entityType = activeUser?.isCompany === true ? 'company' : 'contact';

    const mainUserId = getMainUser(users)?.id;

    let statuscode = 'Canceled';
    api
      .post('api/portfolio/update', {
        ...portfolio,
        statuscode: statuscode,
        approverId: mainUserId,
        ownerId: activeEntityId,
        customerType: entityType,
      })
      .then((res) => {
        const newPortfolios = portfolios.map((p) => {
          if (p.id === portfolio?.id) {
            p.statuscode = statuscode;
          }
          return p;
        });
        setPortfolios(newPortfolios);
        setIsProgress(false);

        // If no more portfolis
        if (
          isToDo &&
          !newPortfolios.find((p) => p.statuscode === 'UnderRevision')
        ) {
          dispatch(setToDoComplete(ToDoEnum.PortfolioAgreement));
        }
      });
  };

  const handleCloseError = () => {
    setApiError({ ...apiError, isOpen: false });
  };

  const [isFetched, setIsFetched] = useState(false);
  const [portfolios, setPortfolios] = useState<FaPortfolioDto[]>([]);
  const [isProgress, setIsProgress] = useState(false);
  const [documentGenerationProgress, setDocumeentGenerationProgress] =
    useState(0);
  const [initSignResponse, setInitSignResponse] = useState<IInitSignResponse>();
  const [isQrCodeFetched, setIsQrCodeFetched] = useState(false);
  const [isSigning, setIsSigning] = useState(false);
  const [apiError, setApiError] = React.useState({
    isOpen: false,
    Error: '',
    InnerError: '',
  });

  useEffect(() => {
    var activeUser = getActiveUser(users);
    var activeEntityId = activeUser?.id;

    api.get(`api/portfolio/${activeEntityId}`).then((res) => {
      setIsFetched(false);
      setPortfolios(res.data as FaPortfolioDto[]);
      setIsFetched(true);
    });
  }, [users]);

  return (
    <React.Fragment>
      <Dialog
        open={apiError.isOpen}
        onClose={handleCloseError}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{apiError.Error}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {apiError.InnerError}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseError}>Ok</Button>
        </DialogActions>
      </Dialog>

      {isProgress || isSigning ? (
        <Box display="flex" justifyContent="center" my={6}>
          <CircularProgress value={documentGenerationProgress} />
        </Box>
      ) : (
        <Grid container spacing={6} overflow={'hidden'}>
          {isQrCodeFetched ? (
            <Grid size={{ xs: 12 }}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div>
                  <img
                    style={{ maxWidth: '300px' }}
                    alt="Embedded QR Code"
                    src={
                      'data:image/jpeg;base64,' +
                      initSignResponse?.qrCodeAsBase64
                    }
                  />
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                      size="small"
                      sx={{ ml: 2 }}
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setIsQrCodeFetched(false);
                        setIsFetched(false);
                        setIsSigning(true);
                        window.location.href = `bankid:///?autostarttoken=${initSignResponse?.autoStartToken}&redirect=null`;
                      }}
                    >
                      {t('signing.ConfirmSameDevice')}
                    </Button>
                  </div>
                </div>
              </div>
            </Grid>
          ) : (
            <>
              {!isToDo ? (
                <Grid size={{ xs: 12 }}>
                  {isFetched ? (
                    <>
                      <Typography variant="h6" gutterBottom display="inline">
                        {t('Accepted')}
                      </Typography>
                      <Box height={'100%'}>
                        <DataGrid
                          rows={portfolios.filter(
                            (p) => p.statuscode === 'Won'
                          )}
                          disableRowSelectionOnClick
                          columns={columns}
                          columnHeaderHeight={36}
                          autoHeight
                          rowHeight={40}
                          getRowId={(row) =>
                            `${row.faPortfolioKey} - ${row.portfolioType} ${row.portfolioAgreement}`
                          }
                        />
                      </Box>
                    </>
                  ) : (
                    <div>
                      <Box display="flex" justifyContent="center" my={6}>
                        <CircularProgress />
                      </Box>
                      <Box display="flex" justifyContent="center" my={6}>
                        <Typography> {t('LongLoading')}</Typography>
                      </Box>
                    </div>
                  )}
                </Grid>
              ) : (
                ''
              )}
            </>
          )}

          {isQrCodeFetched ? (
            ''
          ) : (
            <>
              {isToDo ? (
                <Grid size={{ xs: 12 }}>
                  {isFetched ? (
                    <>
                      <Typography variant="h6" gutterBottom display="inline">
                        {t('ForApproval')}
                      </Typography>
                      <Box
                        height={'100%'}
                        sx={{
                          display: {
                            xs: 'none',
                            sm: 'none',
                            md: 'none',
                            lg: 'block',
                          },
                        }}
                      >
                        <DataGrid
                          rows={portfolios.filter(
                            (p) => p.statuscode === 'UnderRevision'
                          )}
                          disableRowSelectionOnClick
                          columns={columnsApproval}
                          columnHeaderHeight={36}
                          rowHeight={40}
                          autoPageSize
                          hideFooterRowCount
                          getRowId={(row) =>
                            `${row.faPortfolioKey} - ${row.portfolioType} ${row.portfolioAgreement}`
                          }
                        />
                      </Box>
                      <Box
                        height={'100%'}
                        sx={{ display: { md: 'block', lg: 'none' } }}
                      >
                        <DataGrid
                          rows={portfolios.filter(
                            (p) => p.statuscode === 'UnderRevision'
                          )}
                          disableRowSelectionOnClick
                          columns={columnsApprovalMobile}
                          columnHeaderHeight={36}
                          rowHeight={108}
                          autoPageSize
                          hideFooterRowCount
                          disableColumnMenu
                          getRowId={(row) =>
                            `${row.faPortfolioKey} - ${row.portfolioType} ${row.portfolioAgreement}`
                          }
                        />
                      </Box>
                    </>
                  ) : (
                    <div>
                      <Box display="flex" justifyContent="center" my={6}>
                        <CircularProgress />
                      </Box>
                      <Box display="flex" justifyContent="center" my={6}>
                        <Typography> {t('LongLoading')}</Typography>
                      </Box>
                    </div>
                  )}
                </Grid>
              ) : (
                ''
              )}
            </>
          )}
        </Grid>
      )}
    </React.Fragment>
  );
}

export default PortfolioAgreement;
